import { Col, Radio, Row, Table, Form } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { ColumnsType } from 'antd/lib/table'
import { RadioChangeEvent } from 'antd/lib/radio'
import styled from 'styled-components'
import { FundCCMCResponse } from '../../../../shared/api/models/FundDetails'
import { MultiManagerPlatformType } from '../../../../shared/api/models/FirmDetails'
import { FormInstance } from 'antd/lib/form'

const StyledRadio = styled(Radio)`
  display: flex;
  flex-direction: row;
`
const TextWrapper = styled.p`
  text-align: center;
  white-space: wrap;
`

type Props = {
  isFirmUpdated?: boolean
  form: FormInstance
  loadingCMICData?: boolean
  loadingMultiManagerPlatform?: boolean
  firmCMICData?: {
    responses: FundCCMCResponse[]
  }
  multiManagerPlatform?: { funds: MultiManagerPlatformType[] }
  onChangeAll?: (newData: MultiManagerPlatformType[]) => void
}
export const FirmMultiManagerPodPlatform: React.FC<Props> = ({
  isFirmUpdated,
  form,
  onChangeAll,
  loadingCMICData,
  loadingMultiManagerPlatform,
  firmCMICData,
  multiManagerPlatform,
}) => {
  const [fundRowData, setFundRowData] = useState<MultiManagerPlatformType[]>([])
  const [allOption, setAllOption] = useState<'yes' | 'no' | 'null'>('null')

  useEffect(() => {
    if (!firmCMICData || !multiManagerPlatform) {
      setFundRowData([])
      return
    }

    const newData: MultiManagerPlatformType[] = firmCMICData.responses.map((f) => {
      const foundData = multiManagerPlatform.funds.find((r) => r.fund_id === f.fund_id)
      return {
        fund_id: f.fund_id,
        fund_name: f.fund_name,
        multi_manager_platform: foundData?.multi_manager_platform || false,
      }
    })

    setFundRowData(newData)
  }, [multiManagerPlatform, firmCMICData])

  useEffect(() => {
    if (!isFirmUpdated) {
      setAllOption('null')
    }
  }, [isFirmUpdated])

  const columns: ColumnsType<MultiManagerPlatformType> = useMemo(() => {
    const updateIsMultiManager = (id: string, newValue: boolean) => {
      setFundRowData((prev) => {
        setAllOption('null')
        const findIndex = prev.findIndex((row) => row.fund_id === id)
        const newData = [...prev]
        newData[findIndex].multi_manager_platform = newValue
        return newData
      })
    }
    return [
      { title: 'FUND', key: 'fund_name', dataIndex: 'fund_name' },
      {
        title: 'YES',
        width: '16.67%',
        align: 'center',
        key: 'yes_option',
        dataIndex: 'multi_manager_platform',
        render: (_value, record) => {
          return (
            <Form.Item name={['_multiManagerPlatformChange', record.fund_id, 'multi_manager_platform']}>
              <Radio.Group
                onChange={(e) => {
                  updateIsMultiManager(record.fund_id, e.target.value)
                }}
              >
                <Radio value={true} style={{ marginLeft: '8px' }} />
              </Radio.Group>
            </Form.Item>
          )
        },
      },
      {
        title: 'NO',
        width: '16.67%',
        align: 'center',
        key: 'no_option',
        dataIndex: 'multi_manager_platform',
        render: (_value, record) => {
          return (
            <Form.Item name={['_multiManagerPlatformChange', record.fund_id, 'multi_manager_platform']}>
              <Radio.Group
                onChange={(e) => {
                  updateIsMultiManager(record.fund_id, e.target.value)
                }}
              >
                <Radio value={false} style={{ marginLeft: '8px' }} />
              </Radio.Group>
            </Form.Item>
          )
        },
      },
    ]
  }, [])

  const onSelectAllOptions = (e: RadioChangeEvent) => {
    setAllOption(e.target.value)
    let newValue: boolean
    if (e.target.value === 'yes') {
      newValue = true
    } else if (e.target.value === 'no') {
      newValue = false
    } else {
      return
    }
    setFundRowData((prev) => {
      const newData = prev.map((row) => {
        return {
          ...row,
          multi_manager_platform: newValue,
        }
      })
      const newDataRecord = newData.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.fund_id]: cur,
        }
      }, {} as Record<string, MultiManagerPlatformType>)
      form.setFieldsValue({ _multiManagerPlatformChange: newDataRecord })
      onChangeAll && onChangeAll(newData)
      return newData
    })
  }

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
      <div>{`Please identify whether the funds below employ a multi-portfolio-manager / multi-investment-team platform
approach, whereby numerous specialized internal investment teams are each tasked with managing a portion of the
total fund AUM. This structure is commonly referred to as a "pod shop" approach.`}</div>
      <Radio.Group value={allOption} onChange={onSelectAllOptions} style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Row>
          <Col span={16} />
          <Col
            span={4}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <StyledRadio value="yes">
              <TextWrapper>{`Select 'Yes' for all`}</TextWrapper>
            </StyledRadio>
          </Col>
          <Col
            span={4}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <StyledRadio value="no">
              <TextWrapper>{`Select 'No' for all`}</TextWrapper>
            </StyledRadio>
          </Col>
        </Row>
      </Radio.Group>

      <Table
        loading={loadingCMICData || loadingMultiManagerPlatform}
        columns={columns}
        dataSource={fundRowData}
        rowKey={(f) => f.fund_id}
      />
    </div>
  )
}
